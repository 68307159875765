<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{
              name: `${page_route}${store_mode ? '' : '.view'}`,
              params: { id: id },
            }"
          />
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-form ref="form_data" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'GENERAL'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="Código*"
                      v-model="data.code"
                      :rules="rules.text50Required"
                      type="text"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-autocomplete
                      label="Sistema*"
                      v-model="data.cpt_system_id"
                      :items="cpt_system"
                      item-value="id"
                      :item-text="(v) => v.cpt_system"
                      :loading="cpt_system_ldg"
                      :rules="rules.required"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-autocomplete
                      label="Especialidad*"
                      v-model="data.cpt_specialty_id"
                      :items="cpt_specialties"
                      item-value="id"
                      :item-text="(v) => v.cpt_specialty"
                      :loading="cpt_specialties_ldg"
                      :rules="rules.required"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="Monto*"
                      v-model="data.amount"
                      :rules="rules.required"
                      type="number"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="12">
                    <v-textarea
                      label="Descripción*"
                      v-model="data.cpt"
                      :rules="rules.required"
                      rows="1"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'PORCENTAJES'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Anestesiologo*"
                      v-model="data.anest_percent"
                      :rules="rules.required"
                      type="number"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Primer ayudante*"
                      v-model="data.assist_percent"
                      :rules="rules.required"
                      type="number"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Otros ayudantes*"
                      v-model="data.others_percent"
                      :rules="rules.required"
                      type="number"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" class="text-right">
            <BtnCircle
              :tLabel="store_mode ? 'Crear' : 'Editar'"
              tPosition="top"
              :icon="store_mode ? 'mdi-plus' : 'mdi-pencil'"
              :color="store_mode ? 'success' : 'info'"
              size="small"
              :click="handleSubmit"
              :loading="loading"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
//import functions
import Axios from "axios";
import { storeUpdate, show } from "../../requests/pageRequest";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  rules,
} from "../../control";
//import components
import BtnCircle from "../../components/BtnCircle.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    FaqDlg,
  },
  data() {
    return {
      //route name and page title
      page_route: "cpts",
      //vars control
      id: this.$route.params.id ? this.$route.params.id : 0,
      store_mode: this.$route.params.id ? false : true,
      login: this.$store.getters.getLogin,
      loading: false,
      //data for api
      data: {
        id: null,
        name: "",
        amount: 0,
        anest_percent: "30",
        assist_percent: "20",
        others_percent: "10",
        cpt_system_id: null,
        cpt_specialty_id: null,
      },
      //rules form data
      rules: rules(),
      cpt_system: [],
      cpt_system_ldg: true,
      cpt_specialties: [],
      cpt_specialties_ldg: true,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    handleSubmit() {
      //validate form data
      if (this.$refs.form_data.validate()) {
        this.$swal
          .fire(
            msgConfirm(
              `¿Confirma la ${
                this.store_mode ? "creación" : "edición"
              } del registro?`
            )
          )
          .then((res) => {
            if (res.isConfirmed) {
              this.loading = true;
              //store or update action
              storeUpdate(
                this.page_route,
                this.login.token,
                this.id,
                this.data,
                this.login.id
              ).then((res) => {
                this.$swal.fire(
                  msgAlert(res.success ? "success" : "error", res.message)
                );

                res.success
                  ? this.$router.push({
                      name: `${this.page_route}${
                        this.store_mode ? "" : ".view"
                      }`,
                      params: { id: this.id },
                    })
                  : console.log(res.message);
              });

              this.loading = false;
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    if (!this.store_mode) {
      this.loading = true;

      show(this.page_route, this.login.token, this.id).then((res) => {
        this.data = res;
        this.loading = false;
      });
    }

    Axios.get(
      `${URL_API}/catalog?name=cpt_systems&no_order=false`,
      headersToken(this.login.token)
    ).then((resp) => {
      this.cpt_system = resp.data.data;
      this.cpt_system_ldg = false;
    });

    Axios.get(
      `${URL_API}/catalog?name=cpt_specialties&no_order=false`,
      headersToken(this.login.token)
    ).then((resp) => {
      this.cpt_specialties = resp.data.data;
      this.cpt_specialties_ldg = false;
    });
  },
};
</script>